
import { defineComponent } from 'vue';
import ExpandButton from '@/components/ui/ExpandButton.vue';
import { mapGetters } from 'vuex';

export default defineComponent({
  name: "ContentSection",
  components: {ExpandButton},
  computed: {
    ...mapGetters(['isProposalEditable'])
  },
  props: {
    showRemoveButton: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      expand: false
    }
  }
})
