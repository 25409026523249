
import { defineComponent } from 'vue';

export default defineComponent({
  name: "Switch",
  props: {
    id: {
      required: true,
      type: String
    },
    modelValue: {
      type: Boolean
    }
  },
  computed: {
    value: {
      get(): boolean {
        return this.modelValue;
      },
      set(inputValue: boolean): void {
        this.$emit('update:modelValue', inputValue);
      }
    }
  }
})
