
import { defineComponent, PropType } from 'vue';
import Modal from '@/components/ui/Modal.vue';
import OutlineButton from '@/components/ui/OutlineButton.vue';

export interface ProposalFilters {
  country: FilterOption[],
  supplier: FilterOption[],
  product: FilterOption[],
  contract: FilterOption[],
  service: FilterOption[],
  weight: FilterOption[]
}

export interface FilterOption {
  checked: boolean;
  value: string | number;
}

export default defineComponent({
  name: "SalePricesFormFiltersModal",
  components: {OutlineButton, Modal},
  data(): { localFilters: ProposalFilters | undefined } {
    return {
      localFilters: undefined
    }
  },
  mounted() {
    this.localFilters = JSON.parse(JSON.stringify(this.filters));
  },
  watch: {
    filters(newValue) {
      this.localFilters = JSON.parse(JSON.stringify(newValue));
    }
  },
  props: {
    showFilters: {
      type: Boolean,
      default: false
    },
    filters: {
      required: true,
      type: Object as PropType<ProposalFilters>
    }
  },
  methods: {
    handleFiltersConfirmClick(): void {
      this.$emit('filtersChange', this.localFilters)
    },
    handleResetFiltersButtonClick(): void {
      if (!this.localFilters) {
        return;
      }

      [
          ...this.localFilters.country,
          ...this.localFilters.product,
          ...this.localFilters.service,
          ...this.localFilters.contract,
          ...this.localFilters.supplier,
          ...this.localFilters.weight,
      ].forEach((filter: FilterOption) => {
        filter.checked = true;
      })

      this.$emit('filtersChange', this.localFilters);
      this.$emit('update:showFilters', false);
    }
  },
  computed: {
    show: {
      get(): boolean {
        return this.showFilters
      },
      set(value: boolean) {
        this.$emit('update:showFilters', value);
      }
    }
  }
})
