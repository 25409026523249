
import { PropType } from 'vue';
import { defineComponent } from 'vue';
import Badge from '@/components/ui/Badge.vue';
import ProposalStatus from '@/components/proposal/ProposalStatus.vue';
import { Customer } from '@/model/customer';
import OutlineButton from '@/components/ui/OutlineButton.vue';
import { Proposal } from '@/model/proposal';
import Modal from '@/components/ui/Modal.vue';
import CustomerHeader from '@/components/customer/CustomerHeader.vue';
import CustomerDetailsModal from '@/components/customer/CustomerDetailsModal.vue';
import { ProposalMutation } from '@/store/proposal/proposal.mutation';
import { mapGetters } from 'vuex';
import ProposalSettingsModal from '@/components/proposal/form/ProposalSettingsModal.vue';

export default defineComponent({
  name: "ProposalHeader",
  components: {
    ProposalSettingsModal, CustomerDetailsModal, CustomerHeader, Modal, OutlineButton, Badge, ProposalStatus
  },
  props: {
    customer: {
      required: true,
      type: Object as PropType<Customer>
    },
    proposal: {
      type: Object as PropType<Proposal>,
      required: true
    },
    showGrossMarginButton: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      showClientModal: false,
      showSettingsModal: false,
      settings: {
        description: '',
        validFrom: '',
        validTo: ''
      }
    }
  },
  methods: {
    handleSettingsConfirmClick(): void {
      if (!this.isProposalEditable) {
        return;
      }

      this.$store.commit(ProposalMutation.SET_PROPOSAL_DESCRIPTION, this.settings.description);
      this.$store.commit(ProposalMutation.SET_PROPOSAL_VALID_FROM, this.settings.validFrom);
      this.$store.commit(ProposalMutation.SET_PROPOSAL_VALID_TO, this.settings.validTo);
    },
  },
  computed: {
    ...mapGetters(['isProposalEditable', 'proposalValidTo', 'proposalValidFrom']),
    validFromFormatted(): string | null {
      if (!this.proposalValidFrom) {
        return null;
      }

      return new Date(this.proposalValidFrom).toLocaleDateString();
    },
    validToFormatted(): string | null {
      if (!this.proposalValidTo) {
        return null;
      }

      return new Date(this.proposalValidTo).toLocaleDateString();
    },
    areDatesValid(): boolean {
      return !!this.settings.validTo && !!this.settings.validFrom && (new Date(this.settings.validFrom) <= new Date(this.settings.validTo));
    }
  }
})
