<template>
  <div class="badge">
    <span>i</span>
  </div>
</template>

<script>
import { defineComponent } from 'vue';

export default defineComponent({
  name: "Badge"
})
</script>

<style scoped lang="scss">
@import "../../assets/styles/variables";

.badge {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 8px;

  width: 20px;
  height: 20px;
  left: 168px;
  top: 11px;

  background: $color-green-dark;
  border-radius: 100px;
  color: $color-white;
  font-size: $text-light;
  cursor: pointer;
}
</style>