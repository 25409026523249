
import { defineComponent, PropType } from 'vue';
import { mapGetters } from 'vuex';
import DatePicker from 'vue-datepicker-next';
import Alert from '@/components/ui/Alert.vue';

export interface ProposalSettings {
  description: string,
  validFrom: string,
  validTo: string
}

export default defineComponent({
  name: "ProposalSettingsModal",
  components: {Alert, DatePicker},
  props: {
    modelValue: {
      type: Object as PropType<ProposalSettings>
    },
    description: {
      type: String,
      required: true
    },
    validFrom: {
      required: true
    },
    validTo: {
      required: true
    }
  },
  mounted(): void {
    this.$emit('update:modelValue', {description: this.description, validFrom: this.validFrom, validTo: this.validTo})
  },
  computed: {
    ...mapGetters(['isProposalEditable']),
    localDescription: {
      get(): string {
        if (!this.modelValue) {
          return '';
        }

        return this.modelValue?.description;
      },
      set(value: string): void {
        this.$emit('update:modelValue', {...this.modelValue, description: value})
      }
    },
    localValidFrom: {
      get(): Date | null {
        if (!this.modelValue || !this.modelValue?.validFrom) {
          return null;
        }

        return new Date(this.modelValue?.validFrom);
      },
      set(value: Date): void {
        this.$emit('update:modelValue', {...this.modelValue, validFrom: value?.toString()})
      }
    },
    localValidTo: {
      get(): Date | null {
        if (!this.modelValue || !this.modelValue?.validTo) {
          return null;
        }

        return new Date(this.modelValue?.validTo);
      },
      set(value: Date): void {
        this.$emit('update:modelValue', {...this.modelValue, validTo: value?.toString()})
      }
    },
    areDatesInvalid(): boolean {
      return !this.localValidTo || !this.localValidFrom || (this.localValidFrom > this.localValidTo)
    }
  }
})
