
import { defineComponent, PropType } from 'vue';
import { SalePriceRow } from '@/store/proposal/proposal.module';

export default defineComponent({
  name: "SurchargeProductDetails",
  props: {
    salePriceRow: {
      required: true,
      type: Object as PropType<SalePriceRow>
    }
  },
  computed: {
    isSelected(): boolean {
      return this.salePriceRow.selected;
    }
  }
})
