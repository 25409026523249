
import { defineComponent } from 'vue';
import OutlineButton from '@/components/ui/OutlineButton.vue';
import { Customer } from '@/model/customer';
import customersService from '@/services/customers.service';
import ContentLoader from '@/components/ui/ContentLoader.vue';
import SearchInput from '@/components/ui/form/SearchInput.vue';

interface ProposalClientState {
  customers: undefined | Customer[],
  selectedCustomer: undefined | string,
  search: string | undefined,
  isLoading: boolean,
  country: string | undefined
}

export default defineComponent({
  name: "ProposalClient",
  components: {SearchInput, ContentLoader, OutlineButton},
  data(): ProposalClientState {
    return {
      customers: undefined,
      selectedCustomer: undefined,
      search: undefined,
      isLoading: false,
      country: undefined
    }
  },
  mounted(): void {
    this.fetchCustomers();
  },
  watch: {
    // search(): void {
    //   this.fetchCustomers();
    // },
    country(): void {
      this.fetchCustomers();
    }
  },
  methods: {
    handleSelectClick(): void {
      this.$router.push({name: 'proposal.form', params: {clientId: this.selectedCustomer}})
    },
    handleCustomerClick(customer: Customer) {
      if (customer.id === this.selectedCustomer) {
        this.selectedCustomer = undefined;
        return;
      }

      this.selectedCustomer = customer.id;
    },
    fetchCustomers(): void {
      this.isLoading = true;

      customersService
          .getList(this.search, this.country)
          .then(({data}) => this.customers = data)
          .finally(() => this.isLoading = false);
    }
  },
  computed: {
    elements(): undefined | Customer[] {
      if (!this.customers) {
        return undefined;
      }

      if (!this.search) {
        return this.customers;
      }

      return this.customers.filter((customer: Customer) => customer.companyName.toLowerCase().includes(('' + this.search).toLowerCase()));
    }
  }
})
