
import { defineComponent } from 'vue';
import LayoutHeader from '@/components/layout/LayoutHeader.vue';
import ProposalHeader from '@/components/proposal/ProposalHeader.vue';
import TabsNavigation from '@/components/ui/TabsNavigation.vue';
import PercentInput from '@/components/ui/PercentInput.vue';
import OutlineButton from '@/components/ui/OutlineButton.vue';
import { mapGetters } from 'vuex';
import ContentLoader from '@/components/ui/ContentLoader.vue';
import { Customer } from '@/model/customer';
import customersService from '@/services/customers.service';
import { ProposalAction } from '@/store/proposal/proposal.action';
import { ProposalMutation } from '@/store/proposal/proposal.mutation';
import { ProposalSalePrice } from '@/model/proposal-sale-price';
import { AppMutation } from '@/store/app/app.mutation';
import { NotificationType } from '@/store/app/notification/notification-type';
import { AxiosResponse } from 'axios';
import Modal from '@/components/ui/Modal.vue';
import { Supplier } from '@/model/supplier';

interface ProposalCreateState {
  availableSuppliers: Supplier[];
  showStartingPopup: boolean;
  startingGrossMargin: number | undefined;
  isSaving: boolean;
  customer: Customer | undefined;
  customerId: string;
  showChangeGrossMarginConfirm: boolean;
}

export default defineComponent({
  name: "ProposalCreate",
  components: {
    Modal,
    ContentLoader,
    OutlineButton,
    PercentInput,
    TabsNavigation,
    ProposalHeader,
    LayoutHeader
  },
  data(): ProposalCreateState {
    return {
      availableSuppliers: [],
      showStartingPopup: true,
      startingGrossMargin: undefined,
      isSaving: false,
      customer: undefined,
      customerId: '',
      showChangeGrossMarginConfirm: false
    }
  },
  computed: {
    ...mapGetters(['proposal', 'isProposalsProcessing']),
    isStartingGrossMarginInvalid(): boolean {
      return !this.startingGrossMargin && 0 != this.startingGrossMargin;
    }
  },
  async mounted() {
    this.$store.commit(ProposalMutation.CLEAR_PROPOSAL_SUPPLIERS_CHOICES);
    this.customerId = 'string' === typeof this.$route.params.clientId
        ? this.$route.params.clientId
        : this.$route.params.clientId[0];

    customersService
        .getOne(this.customerId)
        .then(({data}) => this.customer = data);

    const reuseProposalId = this.$route.query.reuse;

    if (reuseProposalId) {
      this
          .$store
          .dispatch(
              ProposalAction.LOAD_SALE_PRICES_ROWS_FOR_REUSING_PROPOSAL,
              {
                proposalId: reuseProposalId,
                loadAllProducts: this.$route.query.loadAllProducts || true,
                keepSalePrices: this.$route.query.keepSalePrices || false
              }
          );
    }
    if (!reuseProposalId) {
      this.$store.dispatch(
          ProposalAction.LOAD_PROPOSAL_AVAILABLE_SUPPLIERS,
          this.customerId,
      );
    }
  },
  unmounted() {
    this.$store.commit(ProposalMutation.CLEAR_PROPOSAL_SUPPLIERS_CHOICES);
    this.$store.commit(ProposalMutation.CLEAR_PROPOSAL);
    this.$store.commit(ProposalMutation.CLEAR_SALE_PRICES);
  },
  methods: {
    setupGrossMargin(): void {
      if (this.isStartingGrossMarginInvalid) {
        return;
      }

      this.$store.commit(ProposalMutation.SETUP_GROSS_MARGIN, this.startingGrossMargin);
      this.showStartingPopup = false;
    },
    saveProposal(): void {
      this.isSaving = true;

      this.$store.dispatch(ProposalAction.SAVE_NEW_PROPOSAL, this.customerId)
          .then((response: AxiosResponse<ProposalSalePrice>) => {
            this.$store.commit(AppMutation.ADD_NOTIFICATION, {
              type: NotificationType.SUCCESS,
              title: 'general.notifications.success',
              content: 'proposal.create.notifications.save_success'
            })

            this.$router.push({
              name: 'proposal.show.outbound',
              params: {customerId: this.customerId, proposalId: response.data.id}
            })
          })
          .catch(() => {
            this.$store.commit(AppMutation.ADD_NOTIFICATION, {
              type: NotificationType.ERROR,
              title: 'general.notifications.error',
              content: 'proposal.create.notifications.save_error'
            })
          })
          .finally(() => {
            this.isSaving = false;
          });
    }
  }
})
