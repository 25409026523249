
import { defineComponent } from 'vue';
import OutlineButton from '@/components/ui/OutlineButton.vue';
import ProposalRow from '@/components/proposal/list/ProposalRow.vue';
import ContentLoader from '@/components/ui/ContentLoader.vue';
import { ProposalAction } from '@/store/proposal/proposal.action';
import { mapGetters } from 'vuex';
import SearchInput from '@/components/ui/form/SearchInput.vue';
import { ProposalCustomer } from '@/model/proposal-customer';
import ProposalsListFilters, { ProposalsFilters } from '@/components/proposal/list/ProposalsListFilters.vue';
import { ProposalStatus } from '@/model/proposal-status';

export default defineComponent({
  name: "ProposalsList",
  components: {ProposalsListFilters, SearchInput, ContentLoader, ProposalRow, OutlineButton},
  mounted(): void {
    this.$store.dispatch(ProposalAction.FETCH_LIST_ELEMENTS);
  },
  data() {
    return {
      search: '',
      showFiltersModal: false,
      filters: {
        status: [ProposalStatus.ACCEPTED, ProposalStatus.DRAFT, ProposalStatus.AWAITING, ProposalStatus.REJECTED]
      },
      showFilters: false
    }
  },
  methods: {
    handleFiltersChange(filters: ProposalsFilters): void {
      this.filters = JSON.parse(JSON.stringify(filters));
    }
  },
  computed: {
    ...mapGetters(['proposalList', 'isProposalsProcessing']),
    filteredProposals(): ProposalCustomer[] {
      if (!this.proposalList) {
        return [];
      }

      const proposals = this
          .proposalList
          .filter((proposal: ProposalCustomer) => this.filters.status.includes(proposal.status));

      if (!this.search) {
        return proposals;
      }

      return proposals
          .filter((proposal: ProposalCustomer) => {
            return proposal.customer.name.toLowerCase().includes(this.search.toLowerCase())
                || proposal.id?.toString().includes(this.search.toLowerCase())
                || proposal.description?.toLowerCase().includes(this.search.toLowerCase());
          });
    }
  }
})
