import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, normalizeClass as _normalizeClass, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-29f8d85d"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "flex flex-row" }
const _hoisted_2 = { class: "flex flex-col w-1/2" }
const _hoisted_3 = { class: "text-light text-gray-600" }
const _hoisted_4 = { class: "pb-2" }
const _hoisted_5 = { class: "flex flex-col w-1/2" }
const _hoisted_6 = { class: "text-light text-gray-600" }
const _hoisted_7 = { class: "pb-2" }
const _hoisted_8 = { class: "flex flex-row" }
const _hoisted_9 = { class: "flex flex-col w-1/2" }
const _hoisted_10 = { class: "text-light text-gray-600" }
const _hoisted_11 = { class: "pb-2" }
const _hoisted_12 = { class: "flex flex-col w-1/2" }
const _hoisted_13 = { class: "text-light text-gray-600" }
const _hoisted_14 = { class: "pb-2" }
const _hoisted_15 = { class: "flex flex-row" }
const _hoisted_16 = { class: "flex flex-col w-1/2" }
const _hoisted_17 = { class: "text-light text-gray-600" }
const _hoisted_18 = { class: "pb-2" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", null, [
    _createElementVNode("div", {
      class: _normalizeClass(["flex flex-col w-full p-3 bg-white", {'sale-prices-table__section--selected': _ctx.isSelected}])
    }, [
      _createElementVNode("div", _hoisted_1, [
        _createElementVNode("div", _hoisted_2, [
          _createElementVNode("div", _hoisted_3, _toDisplayString(_ctx.$t('proposal.general.country')), 1),
          _createElementVNode("div", _hoisted_4, _toDisplayString(_ctx.salePriceRow.country || "-"), 1)
        ]),
        _createElementVNode("div", _hoisted_5, [
          _createElementVNode("div", _hoisted_6, _toDisplayString(_ctx.$t('proposal.general.supplier')), 1),
          _createElementVNode("div", _hoisted_7, _toDisplayString(_ctx.salePriceRow.supplier || "-"), 1)
        ])
      ]),
      _createElementVNode("div", _hoisted_8, [
        _createElementVNode("div", _hoisted_9, [
          _createElementVNode("div", _hoisted_10, _toDisplayString(_ctx.$t('proposal.general.product')), 1),
          _createElementVNode("div", _hoisted_11, _toDisplayString(_ctx.salePriceRow.product || "-"), 1)
        ]),
        _createElementVNode("div", _hoisted_12, [
          _createElementVNode("div", _hoisted_13, _toDisplayString(_ctx.$t('proposal.general.contract')), 1),
          _createElementVNode("div", _hoisted_14, _toDisplayString(_ctx.salePriceRow.contract || "-"), 1)
        ])
      ]),
      _createElementVNode("div", _hoisted_15, [
        _createElementVNode("div", _hoisted_16, [
          _createElementVNode("div", _hoisted_17, _toDisplayString(_ctx.$t('proposal.general.service')), 1),
          _createElementVNode("div", _hoisted_18, _toDisplayString(_ctx.salePriceRow.service || "-"), 1)
        ])
      ])
    ], 2)
  ]))
}