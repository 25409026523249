
import { defineComponent } from 'vue';
import { mapGetters } from 'vuex';
import SalePricesForm from '@/components/proposal/form/SalePricesForm.vue';
import { ProposalMutation } from '@/store/proposal/proposal.mutation';

export default defineComponent({
  name: "ProposalCreateOutbound",
  components: {SalePricesForm},
  computed: {
    ...mapGetters(['weightBrackets', 'salePricesRows']),
    grossMarginMutation(): string {
      return ProposalMutation.SETUP_OUTBOUND_GROSS_MARGIN;
    }
  }
})
