
import { defineComponent, PropType } from 'vue';
import OutlineButton from '@/components/ui/OutlineButton.vue';
import Modal from '@/components/ui/Modal.vue';
import EditParagraphModal from '@/components/proposal/form/content/EditParagraphModal.vue';
import { Paragraph } from '@/store/proposal/content/paragraph';
import { mapGetters } from 'vuex';

export default defineComponent({
  name: "ParagraphSection",
  components: {EditParagraphModal, Modal, OutlineButton},
  props: {
    paragraph: {
      type: Object as PropType<Paragraph>,
      required: true
    },
    mutationType: {
      type: String,
      required: true
    },
    saveCallback: {
      type: Function,
      required: false
    }
  },
  computed: {
    ...mapGetters(['isProposalEditable'])
  },
  data() {
    return {
      showEditModal: false,
      title: this.paragraph.title,
      content: this.paragraph.content
    }
  },
  methods: {
    handleConfirmClick() {
      this.$store.commit(this.mutationType, {
        newParagraph: {title: this.title, content: this.content},
        oldParagraph: this.paragraph
      });

      if (this.saveCallback) {
        this.saveCallback();
      }
    }
  },
})
