import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, resolveComponent as _resolveComponent, createBlock as _createBlock, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, withCtx as _withCtx, createVNode as _createVNode, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = {
  key: 0,
  class: "flex flex-col w-full h-max"
}
const _hoisted_2 = { class: "flex flex-col" }
const _hoisted_3 = { key: 1 }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_proposal_suppliers_form_section = _resolveComponent("proposal-suppliers-form-section")!
  const _component_section_button = _resolveComponent("section-button")!
  const _component_spinner = _resolveComponent("spinner")!

  return (_ctx.suppliersChoices?.availableSuppliers?.length)
    ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
        _createElementVNode("div", _hoisted_2, [
          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.suppliersChoices.suppliers, (section, index) => {
            return (_openBlock(), _createBlock(_component_proposal_suppliers_form_section, {
              "section-data": section,
              index: index as number,
              key: index
            }, null, 8, ["section-data", "index"]))
          }), 128))
        ]),
        _createVNode(_component_section_button, {
          class: "w-max mx-auto",
          onClick: _ctx.addNewSection
        }, {
          default: _withCtx(() => [
            _createTextVNode(_toDisplayString(_ctx.$t('proposal.suppliers.modal_form.add_button')), 1)
          ]),
          _: 1
        }, 8, ["onClick"])
      ]))
    : (_openBlock(), _createElementBlock("div", _hoisted_3, [
        _createVNode(_component_spinner)
      ]))
}