
import { defineComponent, PropType } from 'vue';
import SalePriceProductDetails from '@/components/proposal/form/SalePriceProductDetails.vue';
import SalePriceProductDescriptions from '@/components/proposal/form/SalePriceProductDescriptions.vue';
import SalePriceCostPrices from '@/components/proposal/form/SalePriceCostPrices.vue';
import SalePriceActions from '@/components/proposal/form/SalePriceActions.vue';
import { mapGetters } from 'vuex';
import { ProposalMutation } from '@/store/proposal/proposal.mutation';
import Modal from '@/components/ui/Modal.vue';
import { SalePriceRow } from '@/store/proposal/proposal.module';
import OutlineButton from '@/components/ui/OutlineButton.vue';
import SurchargeProductDetails from '@/components/proposal/form/SurchargeProductDetails.vue';

interface SalePricesTableState {
  showDeleteConfirm: boolean,
  fixedHeader: boolean,
  headerTop: number,
  expandedSort: boolean,
  updateRows: number,
  minDetailsWidth: number,
}

export default defineComponent({
  name: "SalePricesTable",
  emits: ["rowDeleteClick", "changeSort"],
  components: {
    SurchargeProductDetails,
    SalePriceProductDescriptions,
    OutlineButton,
    Modal,
    SalePriceActions,
    SalePriceCostPrices,
    SalePriceProductDetails
  },
  props: {
    isSurcharges: {
      required: false,
      type: Boolean,
      default: false,
    },
    weightBrackets: {
      required: true,
      type: Object as PropType<Set<number>>
    },
    salePricesRows: {
      required: true,
      type: Object as PropType<SalePriceRow[]>
    },
    sortColumn: {
      type: String
    }
  },
  data(): SalePricesTableState {
    return {
      showDeleteConfirm: false,
      fixedHeader: false,
      headerTop: 0,
      expandedSort: false,
      updateRows: (new Date()).getTime(),
      minDetailsWidth: 340,
    }
  },
  mounted(): void {
    this.updateHeaderTop();
    window.addEventListener('scroll', this.handleWindowScroll);
    window.addEventListener('resize', this.handleWindowResize);
    this.handleWindowScroll();
    this.handleWindowResize();
  },
  activated () {
    this.updateRows = ( new Date() ).getTime();
    this.updateHeaderTop();
  },
  unmounted(): void {
    window.removeEventListener('scroll', this.handleWindowScroll);
    window.removeEventListener('resize', this.handleWindowResize);
  },
  computed: {
    ...mapGetters(['blockGrossMargin', 'isProposalEditable']),
    hasSelectedElements(): boolean {
      return this.salePricesRows.map((element: SalePriceRow) => element.selected)
          .includes(true);
    },
    isScrollable(): boolean {
      const proposalMain = (this.$refs?.proposalMain as HTMLElement);
      return !!this.updateRows && proposalMain?.offsetWidth < proposalMain?.scrollWidth
    },
    isScrollableLeft(): boolean {
      const proposalMain = (this.$refs?.proposalMain as HTMLElement);
      return !!this.updateRows && this.isScrollable && !!proposalMain.scrollLeft;
    },
    isScrollableRight(): boolean {
      const proposalMain = (this.$refs?.proposalMain as HTMLElement);
      return !!this.updateRows &&
          this.isScrollable &&
          (proposalMain.offsetWidth + proposalMain.scrollLeft) !== proposalMain.scrollWidth;
    },
  },
  methods: {
    updateHeaderTop() {
      this.headerTop = (this.$refs.proposalHeader as HTMLElement).getBoundingClientRect().top;
    },
    handleWindowScroll(): void {
      this.updateRows = ( new Date() ).getTime();
      this.handleProposalMainScroll();
      this.fixedHeader = window.scrollY > this.headerTop;
    },
    handleWindowResize(): void {
      this.updateRows = ( new Date() ).getTime();
    },
    handleDeleteClick(): void {
      this.$emit('rowDeleteClick');
    },
    handleBlockClick(): void {
      this.$store.commit(ProposalMutation.SET_BLOCK_GROSS_MARGIN, !this.blockGrossMargin);
    },
    handleSortClick(column: string): void {
      this.expandedSort = false;
      this.$emit('changeSort', column);
    },
    handleProposalMainScroll(): void {
      this.updateRows = ( new Date() ).getTime();
      const headerScroller = ( this.$refs.proposalWeightBracketsScroller as HTMLElement );
      const mainScroller = ( this.$refs.proposalMain as HTMLElement );
      headerScroller.scrollLeft = mainScroller.scrollLeft;
    },
    scrollLeft(): void {
      const mainScroller = ( this.$refs.proposalMain as HTMLElement );
      mainScroller.scrollLeft = Math.max(( mainScroller.scrollLeft - 300 ), 0);
    },
    scrollRight(): void {
      const mainScroller = ( this.$refs.proposalMain as HTMLElement );
      mainScroller.scrollLeft = Math.min(
          ( mainScroller.scrollLeft + 300 ),
          ( mainScroller.scrollWidth - mainScroller.offsetWidth )
      );
    },
  },
})
