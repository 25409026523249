
import { defineComponent, PropType } from 'vue';
import OutlineButton from '@/components/ui/OutlineButton.vue';
import { ProposalAction } from '@/store/proposal/proposal.action';
import { Proposal } from '@/model/proposal';
import { ProposalExportType } from '@/model/proposal-export-type';

export default defineComponent({
  name: "ProposalAwaitingActions",
  components: {OutlineButton},
  props: {
    proposal: {
      required: true,
      type: Object as PropType<Proposal>
    }
  },
  methods: {
    exportPdf(): void {
      this.$store.dispatch(ProposalAction.EXPORT_PROPOSAL, {proposalId: this.proposal.id, type: ProposalExportType.PDF})
    },
    exportXls(): void {
      this.$store.dispatch(ProposalAction.EXPORT_PROPOSAL, {proposalId: this.proposal.id, type: ProposalExportType.XLS})
    }
  }
})
