import { vModelCheckbox as _vModelCheckbox, createElementVNode as _createElementVNode, withDirectives as _withDirectives, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, resolveComponent as _resolveComponent, createBlock as _createBlock, normalizeClass as _normalizeClass, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-169a9ea2"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "flex-1 px-5 py-5 bg-white" }
const _hoisted_2 = { class: "flex flex-col h-full justify-between items-end" }
const _hoisted_3 = {
  key: 0,
  class: "block text-light font-bold"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_percent_input = _resolveComponent("percent-input")!

  return (_openBlock(), _createElementBlock("div", {
    class: _normalizeClass({'sale-prices-table__section--selected': _ctx.isSelected})
  }, [
    _createElementVNode("div", _hoisted_1, [
      _createElementVNode("div", _hoisted_2, [
        (_ctx.isProposalEditable)
          ? (_openBlock(), _createElementBlock("span", _hoisted_3, [
              _createElementVNode("label", null, [
                _withDirectives(_createElementVNode("input", {
                  type: "checkbox",
                  "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.isSelected) = $event))
                }, null, 512), [
                  [_vModelCheckbox, _ctx.isSelected]
                ])
              ])
            ]))
          : _createCommentVNode("", true),
        (!_ctx.isSurcharges && _ctx.showGrossMargin && _ctx.isProposalEditable)
          ? (_openBlock(), _createBlock(_component_percent_input, {
              key: 1,
              class: "mt-2 mb-2",
              modelValue: _ctx.rowGrossMargin,
              "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.rowGrossMargin) = $event)),
              changed: _ctx.grossMarginMeta.dirty,
              valid: _ctx.grossMarginMeta.valid
            }, null, 8, ["modelValue", "changed", "valid"]))
          : _createCommentVNode("", true)
      ])
    ])
  ], 2))
}