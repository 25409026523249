
import { defineComponent } from 'vue';
import { mapGetters } from 'vuex';
import SalePricesForm from '@/components/proposal/form/SalePricesForm.vue';
import { ProposalMutation } from '@/store/proposal/proposal.mutation';

export default defineComponent({
  name: "ProposalCreateSurcharges",
  components: {SalePricesForm},
  computed: {
    ...mapGetters(['surchargesRows']),
    grossMarginMutation(): string {
      return ProposalMutation.SETUP_SURCHARGES_GROSS_MARGIN;
    }
  }
})
