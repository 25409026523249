import { vModelText as _vModelText, normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, withDirectives as _withDirectives, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-489afde6"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "percent-input" }
const _hoisted_2 = ["disabled"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _withDirectives(_createElementVNode("input", {
      type: "number",
      maxlength: "5",
      "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.value) = $event)),
      class: _normalizeClass(["percent-input__control default-input", _ctx.inputClass]),
      disabled: _ctx.disabled
    }, null, 10, _hoisted_2), [
      [_vModelText, _ctx.value]
    ]),
    _createElementVNode("span", {
      class: _normalizeClass(["percent-input__sign", {'percent-input__sign--disabled': _ctx.disabled}])
    }, "%", 2)
  ]))
}