
import { defineComponent, PropType } from 'vue';
import ContentLoader from '@/components/ui/ContentLoader.vue';
import SalePricesTable from '@/components/proposal/form/SalePricesTable.vue';
import LayoutWrapper from '@/components/layout/LayourWrapper.vue';
import OutlineButton from '@/components/ui/OutlineButton.vue';
import { ProposalMutation } from '@/store/proposal/proposal.mutation';
import { mapGetters } from 'vuex';
import { SalePriceRow } from '@/store/proposal/proposal.module';
import Switch from '@/components/ui/Switch.vue';
import SalePricesFormFiltersModal, {
  FilterOption,
  ProposalFilters
} from '@/components/proposal/form/SalePricesFormFiltersModal.vue';
import Modal from '@/components/ui/Modal.vue';
import PercentInput from '@/components/ui/PercentInput.vue';
import ProposalSuppliers from '@/components/proposal/suppliers/ProposalSuppliers.vue';

interface SalePricesFormState {
  showSorting: boolean;
  showFilters: boolean;
  filters: ProposalFilters | undefined;
  sortColumn: undefined | string;
  sortDirection: undefined | 'ASC' | 'DESC';
  showCollectiveGrossMarginModal: boolean;
  collectiveGrossMargin: number;
  showChangeGrossMarginConfirm: boolean;
}

export default defineComponent({
  name: "SalePricesForm",
  components: {
    ProposalSuppliers,
    PercentInput,
    Modal,
    SalePricesFormFiltersModal,
    Switch,
    ContentLoader,
    SalePricesTable,
    LayoutWrapper,
    OutlineButton
  },
  data(): SalePricesFormState {
    return {
      showSorting: false,
      showFilters: false,
      filters: undefined,
      sortColumn: undefined,
      sortDirection: undefined,
      showCollectiveGrossMarginModal: false,
      collectiveGrossMargin: 0,
      showChangeGrossMarginConfirm: false
    }
  },
  props: {
    isSurcharges: {
      required: false,
      type: Boolean,
      default: false,
    },
    weightBrackets: {
      required: true,
      type: Object as PropType<Set<number>>
    },
    salePricesRows: {
      required: true,
      type: Object as PropType<SalePriceRow[]>
    },
    grossMarginMutation: {
      required: true,
      type: String
    }
  },
  mounted() {
    this.prepareFilters();
  },
  watch: {
    salePricesRows: {
      deep: false,
      handler() {
        this.prepareFilters();
      }
    },
    filters(): void {
      this.salePricesRows.forEach((row: SalePriceRow) => row.selected = false);
    }
  },
  methods: {
    handleSelectClick(isSelected: boolean): void {
      this.rowsFiltered.forEach((row: SalePriceRow) => row.selected = isSelected);
    },
    handleWarningGrossMarginConfirmClick(): void {
      this.$store.commit(this.grossMarginMutation, this.collectiveGrossMargin);
      this.collectiveGrossMargin = 0;
    },
    prepareFilters() {
      this.filters = {
        country: [],
        supplier: [],
        product: [],
        contract: [],
        service: [],
        weight: []
      }

      this.filters.country = Array.from(this
          .salePricesRows
          .reduce((carry: Set<string>, row: SalePriceRow) => {
            carry.add(row.country)

            return carry
          }, new Set<string>()))
          .map((country: string): FilterOption => ({checked: true, value: country}))

      this.filters.supplier = Array.from(this
          .salePricesRows
          .reduce((carry: Set<string>, row: SalePriceRow) => {
            carry.add(row.supplier)

            return carry
          }, new Set<string>()))
          .map((supplier: string): FilterOption => ({checked: true, value: supplier}))

      this.filters.product = Array.from(this
          .salePricesRows
          .reduce((carry: Set<string>, row: SalePriceRow) => {
            carry.add(row.product)

            return carry
          }, new Set<string>()))
          .map((product: string): FilterOption => ({checked: true, value: product}))

      this.filters.contract = Array.from(this
          .salePricesRows
          .reduce((carry: Set<string>, row: SalePriceRow) => {
            if (row.contract) {
              carry.add(row.contract)
            }

            return carry
          }, new Set<string>()))
          .map((contract: string): FilterOption => ({checked: true, value: contract}))

      this.filters.service = Array.from(this
          .salePricesRows
          .reduce((carry: Set<string>, row: SalePriceRow) => {
            if (row.service) {
              carry.add(row.service)
            }

            return carry
          }, new Set<string>()))
          .map((service: string): FilterOption => ({checked: true, value: service}))

      this.filters.weight = Array.from(this.weightBrackets)
          .map((bracket: number): FilterOption => ({checked: true, value: bracket}))
    },
    handleFiltersChange(filters: ProposalFilters): void {
      this.filters = JSON.parse(JSON.stringify(filters));
    },
    handleRowDeleteClick(): void {
      this.$store.commit(ProposalMutation.REMOVE_SALE_PRICES_ROW)
    },
    handleChangeSort(column: string): void {
      if (this.sortColumn !== column) {
        this.sortColumn = column;
        this.sortDirection = 'ASC';
        return;
      }

      this.sortDirection = this.sortDirection === 'ASC' ? 'DESC' : 'ASC';
    }
  },
  computed: {
    ...mapGetters(['isProposalLoading', 'isProposalEditable']),
    grossMargin: {
      get(): boolean {
        return this.$store.getters.showGrossMargin;
      },
      set(value: boolean): void {
        this.$store.commit(ProposalMutation.SET_GROSS_MARGIN, value);
      }
    },
    costPrice: {
      get(): boolean {
        return this.$store.getters.showCostPrice;
      },
      set(value: boolean): void {
        this.$store.commit(ProposalMutation.SET_COST_PRICE, value);
      }
    },
    displayList: {
      get(): boolean {
        return this.$store.state.proposalModule.displayList;
      },
      set(value: boolean): void {
        this.$store.commit(ProposalMutation.SET_DISPLAY_LIST, value);
      }
    },
    weightBracketsFiltered(): Set<number> {
      if (!this.filters || !this.filters.weight.length) {
        return this.weightBrackets;
      }

      const allowedBrackets = this.filters.weight
          .filter((filter: FilterOption) => filter.checked)
          .map((filter: FilterOption) => filter.value);

      return new Set<number>(
          Array.from(this.weightBrackets)
              .filter(bracket => allowedBrackets.includes(bracket))
      );
    },
    rowsFiltered(): SalePriceRow[] {
      if (!this.filters) {
        return this.salePricesRows;
      }

      const allowedCountries = this.filters.country
          .filter((filter: FilterOption) => filter.checked)
          .map((filter: FilterOption) => filter.value);

      const allowedProducts = this.filters.product
          .filter((filter: FilterOption) => filter.checked)
          .map((filter: FilterOption) => filter.value);

      const allowedSuppliers = this.filters.supplier
          .filter((filter: FilterOption) => filter.checked)
          .map((filter: FilterOption) => filter.value);

      const allowedContracts = ['', ...this.filters.contract
          .filter((filter: FilterOption) => filter.checked)
          .map((filter: FilterOption) => filter.value)];

      const allowedServices = ['', ...this.filters.service
          .filter((filter: FilterOption) => filter.checked)
          .map((filter: FilterOption) => filter.value)];

      return this
          .salePricesRows
          .filter((row: SalePriceRow) => {
            return allowedCountries.includes(row.country) && allowedProducts.includes(row.product)
                && allowedSuppliers.includes(row.supplier) && allowedServices.includes(row.service || '')
                && allowedContracts.includes(row.contract || '');
          })


          .sort((a: SalePriceRow, b: SalePriceRow): number => {
            if (!this.sortColumn) {
              return 0;
            }

            if ('ASC' === this.sortDirection) {
              /* eslint-disable @typescript-eslint/no-explicit-any */
              return `${(a as any)[this.sortColumn]}` > `${(b as any)[this.sortColumn]}` ? 1 : -1;
            }

            return `${(a as any)[this.sortColumn]}` > `${(b as any)[this.sortColumn]}` ? -1 : 1;
          })
    }
  }
})
