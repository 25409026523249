import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createVNode as _createVNode, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-4aeb9417"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "paragraph-section" }
const _hoisted_2 = ["innerHTML"]
const _hoisted_3 = { class: "font-normal" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_outline_button = _resolveComponent("outline-button")!
  const _component_edit_paragraph_modal = _resolveComponent("edit-paragraph-modal")!
  const _component_modal = _resolveComponent("modal")!

  return (_openBlock(), _createElementBlock("section", _hoisted_1, [
    _createElementVNode("h2", null, _toDisplayString(_ctx.title), 1),
    _createElementVNode("div", {
      class: "content ql-editor",
      innerHTML: _ctx.content
    }, null, 8, _hoisted_2),
    (_ctx.isProposalEditable)
      ? (_openBlock(), _createBlock(_component_outline_button, {
          key: 0,
          type: "outline",
          onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.showEditModal=true))
        }, {
          default: _withCtx(() => [
            _createTextVNode(_toDisplayString(_ctx.$t('general.edit')), 1)
          ]),
          _: 1
        }))
      : _createCommentVNode("", true),
    _createVNode(_component_modal, {
      modelValue: _ctx.showEditModal,
      "onUpdate:modelValue": _cache[3] || (_cache[3] = ($event: any) => ((_ctx.showEditModal) = $event)),
      size: "big",
      onConfirmClick: _ctx.handleConfirmClick
    }, {
      title: _withCtx(() => [
        _createElementVNode("span", _hoisted_3, _toDisplayString(_ctx.$t('proposal.content.paragraph.edit')), 1)
      ]),
      default: _withCtx(() => [
        _createVNode(_component_edit_paragraph_modal, {
          title: _ctx.title,
          "onUpdate:title": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.title) = $event)),
          content: _ctx.content,
          "onUpdate:content": _cache[2] || (_cache[2] = ($event: any) => ((_ctx.content) = $event))
        }, null, 8, ["title", "content"])
      ]),
      _: 1
    }, 8, ["modelValue", "onConfirmClick"])
  ]))
}