
import { defineComponent, PropType } from 'vue';
import { Customer } from '@/model/customer';

export default defineComponent({
  name: "CustomerHeader",
  props: {
    customer: {
      type: Object as PropType<Customer>,
      required: true
    }
  }
})
