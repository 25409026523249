import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createBlock as _createBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-6d5ed384"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "tabs" }
const _hoisted_2 = { class: "flex" }
const _hoisted_3 = { class: "tab__content" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_router_link = _resolveComponent("router-link")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("ul", _hoisted_2, [
      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.tabs, (tab, index) => {
        return (_openBlock(), _createBlock(_component_router_link, {
          to: {name: tab.routeName},
          class: "inline-flex tab",
          "exact-active-class": "tab--active",
          key: `${tab.name}-${index}`
        }, {
          default: _withCtx(() => [
            _createElementVNode("li", _hoisted_3, _toDisplayString(_ctx.$t(tab.name)), 1)
          ]),
          _: 2
        }, 1032, ["to"]))
      }), 128))
    ])
  ]))
}