
import { defineComponent } from 'vue';

export default defineComponent({
  name: "ExpandButton",
  props: {
    modelValue: {
      type: Boolean
    }
  },
  methods: {
    handleClick(): void {
      this.$emit('update:modelValue', !this.modelValue);
    }
  }
})
