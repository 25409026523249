
import { defineComponent, PropType } from 'vue';
import { mapGetters } from 'vuex';
import PercentInput from '@/components/ui/PercentInput.vue';
import { SalePriceRow } from '@/store/proposal/proposal.module';
import { SalePrice } from '@/model/sale-price';
import { useField, useForm } from 'vee-validate';
import * as yup from 'yup';

export default defineComponent({
  name: "SalePriceColumn",
  components: {PercentInput},
  setup(props) {
    useForm({
      validationSchema: yup.object({
        grossMargin: yup.number().required().moreThan(0).lessThan(101)
      })
    });

    const {value: grossMarginField, meta: grossMarginMeta} = useField('grossMargin', {}, {
      initialValue: +props.weightBracket.grossMargin,
    });

    return {
      grossMarginField,
      grossMarginMeta
    }
  },
  data(): { salePrice: SalePrice } {
    return {
      salePrice: this.weightBracket
    }
  },
  props: {
    weightBracket: {
      type: Object as PropType<SalePrice>,
      required: true
    },
    salePriceRow: {
      type: Object as PropType<SalePriceRow>,
      required: true
    },
    isDisabled: {
      type: Boolean,
      required: true
    }
  },
  watch: {
    rowGrossMargin(newValue: number): void {
      this.grossMargin = newValue;
    },
    grossMargin(newValue: number): void {
      const amount = this.salePrice.costPrice.amount;

      this.salePrice.price.amount = Math.round(amount * (newValue + 100) / 100);
    }
  },
  computed: {
    ...mapGetters(['showCostPrice', 'showGrossMargin', 'proposal', 'isProposalEditable']),
    rowGrossMargin(): number {
      return this.salePriceRow.grossMargin;
    },
    grossMargin: {
      get(): string | number | undefined {
        if (!this.isProposalEditable) {
          return +this.salePrice.grossMargin;
        }

        return this.salePrice.grossMargin;
      },
      set(value: number): void {
        this.grossMarginField = value;
        this.salePrice.grossMargin = value;
      }
    },
    costPrice(): number {
      return this.salePrice.costPrice.amount / 100;
    },
    price(): number {
      return this.salePrice.price.amount / 100;
    }
  }
})
