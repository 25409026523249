
import { defineComponent } from 'vue';

export default defineComponent({
  name: "PercentInput",
  computed: {
    value: {
      get(): string | number | undefined {
        return this.modelValue;
      },
      set(value: string | number): void {
        this.$emit('update:modelValue', value);
      }
    },
    inputClass(): string {
      if (!this.valid) {
        return 'percent-input__control--invalid';
      }

      if (this.changed) {
        return 'percent-input__control--changed';
      }

      return '';
    }
  },
  props: {
    modelValue: {
      type: [String, Number]
    },
    disabled: {
      type: Boolean,
      default: false
    },
    changed: {
      type: Boolean,
      default: false
    },
    valid: {
      type: Boolean,
      default: true
    }
  }
})
