
import { defineComponent, PropType } from 'vue';
import { ProposalStatus } from '@/model/proposal-status';
import Modal from '@/components/ui/Modal.vue';

export interface ProposalsFilters {
  status: string[]
}

export default defineComponent({
  name: "ProposalsListFilters",
  components: {Modal},
  props: {
    showFilters: {
      type: Boolean,
      default: false
    },
    filters: {
      required: true,
      type: Object as PropType<ProposalsFilters>
    }
  },
  data(): { localFilters: ProposalsFilters | undefined } {
    return {
      localFilters: undefined
    }
  },
  mounted() {
    this.localFilters = JSON.parse(JSON.stringify(this.filters));
  },
  methods: {
    handleFiltersConfirmClick(): void {
      this.$emit('filtersChange', this.localFilters)
    },
  },
  computed: {
    archivedStatus(): string {
      return ProposalStatus.ARCHIVED;
    },
    rejectedStatus(): string {
      return ProposalStatus.REJECTED;
    },
    acceptedStatus(): string {
      return ProposalStatus.ACCEPTED;
    },
    draftStatus(): string {
      return ProposalStatus.DRAFT
    },
    awaitingStatus(): string {
      return ProposalStatus.AWAITING
    },
    show: {
      get(): boolean {
        return this.showFilters
      },
      set(value: boolean) {
        this.$emit('update:showFilters', value);
      }
    }
  }
})
