
import { defineComponent } from 'vue';
import { mapGetters } from 'vuex';
import { ProposalMutation } from '@/store/proposal/proposal.mutation';
import SectionButton from '@/components/ui/SectionButton.vue';
import ProposalSuppliersFormSection from '@/components/proposal/suppliers/ProposalSuppliersFormSection.vue';
import Spinner from '@/components/ui/Spinner.vue';

export default defineComponent({
  name: 'ProposalSuppliersForm',
  components: { Spinner, ProposalSuppliersFormSection, SectionButton },
  computed: {
    ...mapGetters(['suppliersChoices']),
  },
  methods: {
    addNewSection() {
      this.$store.commit(ProposalMutation.ADD_PROPOSAL_SUPPLIERS_SECTION);
    },
  }
})
