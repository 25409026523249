
import { defineComponent } from 'vue';
import Quill from 'quill';
import { Paragraph } from '@/store/proposal/content/paragraph';

export default defineComponent({
  name: "EditParagraphModal",
  props: {
    title: {
      type: String,
      default: ''
    },
    content: {
      type: String,
      default: ''
    }
  },
  computed: {
    localTitle: {
      get(): string {
        return this.title;
      },
      set(value: string): void {
        this.$emit('update:title', value);
      }
    },
    localContent: {
      get(): string {
        return this.content;
      },
      set(value: string) {
        this.$emit('update:content', value);
      }
    }
  },
  data(): { localParagraph: Paragraph } {
    return {
      localParagraph: {title: '', content: ''},
    }
  },
  mounted() {
    const font = Quill.import('attributors/class/font');
    font.whitelist = ['work-sans', 'helvetica'];
    Quill.register(font, true);

    const quill = new Quill('#text-editor', {
      theme: 'snow',
      modules: {
        toolbar: {container: "#toolbar-container"}
      }
    });

    quill.root.innerHTML = this.localContent;

    quill.on('text-change', () => {
      this.localContent = quill.root.innerHTML;
    })
  }
})
