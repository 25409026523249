
import { defineComponent, PropType } from 'vue';
import SalePriceColumn from '@/components/proposal/form/SalePriceColumn.vue';
import { mapGetters } from 'vuex';
import { SalePriceRow } from '@/store/proposal/proposal.module';
import PercentInput from '@/components/ui/PercentInput.vue';

export default defineComponent({
  name: "SalePriceCostPrices",
  components: { PercentInput, SalePriceColumn},
  props: {
    salePriceRow: {
      required: true,
      type: Object as PropType<SalePriceRow>
    },
    weightBrackets: {
      required: true,
      type: Object as PropType<Set<number>>
    }
  },
  computed: {
    ...mapGetters(['showCostPrice', 'showGrossMargin', 'blockGrossMargin', 'isProposalEditable']),
    isSelected(): boolean {
      return this.salePriceRow.selected;
    }
  }
})
