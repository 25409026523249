import { toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "flex" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_outline_button = _resolveComponent("outline-button")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_outline_button, {
      "is-loading": _ctx.isSaving,
      onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.$emit('saveButtonClick')))
    }, {
      default: _withCtx(() => [
        _createTextVNode(_toDisplayString(_ctx.$t('general.button.save')), 1)
      ]),
      _: 1
    }, 8, ["is-loading"])
  ]))
}