
import { defineComponent } from 'vue';
import OutlineButton from '@/components/ui/OutlineButton.vue';
import Modal from '@/components/ui/Modal.vue';
import ProposalSuppliersForm from '@/components/proposal/suppliers/ProposalSuppliersForm.vue';
import { ProposalAction } from '@/store/proposal/proposal.action';
import { mapGetters } from 'vuex';

export default defineComponent({
  name: 'ProposalSuppliers',
  components: {
    ProposalSuppliersForm,
    Modal,
    OutlineButton,
  },
  data() {
    return {
      modalVisibility: false,
    };
  },
  computed: {
    ...mapGetters(['proposal']),
  },
  methods: {
    openModal() {
      this.modalVisibility = true;
    },
    loadPrices() {
      if (this?.proposal && this?.proposal?.id) {
        this.$store.dispatch(
            ProposalAction.UPDATE_SALE_PRICES_ROWS,
            {
              proposalId: this.proposal.id,
              customerId: 'string' === typeof this.$route.params.clientId
                  ? this.$route.params.clientId
                  : this.$route.params.clientId[ 0 ]
            }
        );
      } else {
        this.$store.dispatch(
            ProposalAction.GET_SALE_PRICES_ROWS,
            'string' === typeof this.$route.params.clientId
                ? this.$route.params.clientId
                : this.$route.params.clientId[ 0 ]
        );
      }
    },
  }
})
