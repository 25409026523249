
import { defineComponent } from 'vue';
import OutlineButton from '@/components/ui/OutlineButton.vue';

export default defineComponent({
  name: "ProposalDraftActions",
  components: {OutlineButton},
  props: {
    clientId: {
      type: Number,
      required: true
    },
    proposalId: {
      type: String,
      required: true
    },
    isSaving: {
      type: Boolean,
      required: true
    }
  }
})
