import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_sale_prices_form = _resolveComponent("sale-prices-form")!

  return (_openBlock(), _createBlock(_component_sale_prices_form, {
    "sale-prices-rows": _ctx.salePricesRows,
    "weight-brackets": _ctx.weightBrackets,
    "gross-margin-mutation": _ctx.grossMarginMutation
  }, null, 8, ["sale-prices-rows", "weight-brackets", "gross-margin-mutation"]))
}