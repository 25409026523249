import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, withCtx as _withCtx, createVNode as _createVNode, resolveDynamicComponent as _resolveDynamicComponent, KeepAlive as _KeepAlive, createElementVNode as _createElementVNode, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "proposal-show relative" }
const _hoisted_2 = { class: "proposal-create__content relative" }
const _hoisted_3 = { class: "relative" }
const _hoisted_4 = {
  key: 0,
  class: "flex mt-10 items-center justify-between"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_proposal_header = _resolveComponent("proposal-header")!
  const _component_layout_header = _resolveComponent("layout-header")!
  const _component_tabs_navigation = _resolveComponent("tabs-navigation")!
  const _component_content_loader = _resolveComponent("content-loader")!
  const _component_router_view = _resolveComponent("router-view")!
  const _component_proposal_draft_actions = _resolveComponent("proposal-draft-actions")!
  const _component_proposal_awaiting_actions = _resolveComponent("proposal-awaiting-actions")!
  const _component_proposal_accepted_actions = _resolveComponent("proposal-accepted-actions")!
  const _component_proposal_archived_actions = _resolveComponent("proposal-archived-actions")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_layout_header, null, {
      default: _withCtx(() => [
        (_ctx.customer && _ctx.proposal)
          ? (_openBlock(), _createBlock(_component_proposal_header, {
              key: 0,
              customer: _ctx.customer,
              proposal: _ctx.proposal,
              "show-gross-margin-button": true
            }, null, 8, ["customer", "proposal"]))
          : _createCommentVNode("", true)
      ]),
      _: 1
    }),
    _createVNode(_component_tabs_navigation, { tabs: [
            {name: 'proposal.tabs.outbound', routeName: 'proposal.show.outbound'},
            {name: 'proposal.tabs.returns', routeName: 'proposal.show.returns'},
            {name: 'proposal.tabs.surcharges', routeName: 'proposal.show.surcharges'},
            {name: 'proposal.tabs.content', routeName: 'proposal.show.content'}
        ] }, null, 8, ["tabs"]),
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("div", _hoisted_3, [
        (_ctx.isSaving || _ctx.isProposalsProcessing)
          ? (_openBlock(), _createBlock(_component_content_loader, { key: 0 }))
          : _createCommentVNode("", true),
        _createVNode(_component_router_view, null, {
          default: _withCtx(({ Component }) => [
            (_openBlock(), _createBlock(_KeepAlive, null, [
              (_openBlock(), _createBlock(_resolveDynamicComponent(Component)))
            ], 1024))
          ]),
          _: 1
        })
      ]),
      (_ctx.customer && _ctx.proposal)
        ? (_openBlock(), _createElementBlock("div", _hoisted_4, [
            (_ctx.draftStatus === _ctx.proposalStatus)
              ? (_openBlock(), _createBlock(_component_proposal_draft_actions, {
                  key: 0,
                  "client-id": _ctx.customer.id,
                  "proposal-id": _ctx.proposalId,
                  "is-saving": _ctx.isSaving,
                  onSaveButtonClick: _ctx.saveProposal
                }, null, 8, ["client-id", "proposal-id", "is-saving", "onSaveButtonClick"]))
              : _createCommentVNode("", true),
            (_ctx.awaitingStatus === _ctx.proposalStatus)
              ? (_openBlock(), _createBlock(_component_proposal_awaiting_actions, {
                  key: 1,
                  proposal: _ctx.proposal
                }, null, 8, ["proposal"]))
              : _createCommentVNode("", true),
            (_ctx.acceptedStatus === _ctx.proposalStatus || _ctx.rejectedStatus === _ctx.proposalStatus)
              ? (_openBlock(), _createBlock(_component_proposal_accepted_actions, {
                  key: 2,
                  onReuseClick: _ctx.reuseProposal
                }, null, 8, ["onReuseClick"]))
              : _createCommentVNode("", true),
            (_ctx.archivedStatus === _ctx.proposalStatus)
              ? (_openBlock(), _createBlock(_component_proposal_archived_actions, {
                  key: 3,
                  onReuseClick: _ctx.reuseProposal
                }, null, 8, ["onReuseClick"]))
              : _createCommentVNode("", true)
          ]))
        : _createCommentVNode("", true)
    ])
  ]))
}