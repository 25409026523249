
import { defineComponent } from 'vue';
import LayoutHeader from '@/components/layout/LayoutHeader.vue';
import ProposalHeader from '@/components/proposal/ProposalHeader.vue';
import { Customer } from '@/model/customer';
import customersService from '@/services/customers.service';
import TabsNavigation from '@/components/ui/TabsNavigation.vue';
import ContentLoader from '@/components/ui/ContentLoader.vue';
import OutlineButton from '@/components/ui/OutlineButton.vue';
import { ProposalAction } from '@/store/proposal/proposal.action';
import { mapGetters } from 'vuex';
import { ProposalMutation } from '@/store/proposal/proposal.mutation';
import { AppMutation } from '@/store/app/app.mutation';
import { NotificationType } from '@/store/app/notification/notification-type';
import ProposalDraftActions from '@/components/proposal/form/actions/ProposalDraftActions.vue';
import { ProposalStatus } from '@/model/proposal-status';
import ProposalAwaitingActions from '@/components/proposal/form/actions/ProposalAwaitingActions.vue';
import ProposalAcceptedActions from '@/components/proposal/form/actions/ProposalAcceptedActions.vue';
import ProposalArchivedActions from '@/components/proposal/form/actions/ProposalArchivedActions.vue';

interface ProposalShowState {
  customer: Customer | undefined,
  customerId: string,
  isSaving: boolean,
  proposalId: string
}

export default defineComponent({
  name: "ProposalShow",
  components: {
    ProposalArchivedActions,
    ProposalAcceptedActions,
    ProposalAwaitingActions,
    ProposalDraftActions, OutlineButton, ContentLoader, TabsNavigation, ProposalHeader, LayoutHeader
  },
  data(): ProposalShowState {
    return {
      customer: undefined,
      customerId: '',
      isSaving: false,
      proposalId: '',
    }
  },
  methods: {
    reuseProposal(): void {
      this.$router.push({name: 'proposal.form', params: {clientId: this.customerId}, query: {reuse: this.proposalId}});
    },
    saveProposal(): void {
      this.isSaving = true;

      this.$store.dispatch(ProposalAction.UPDATE_PROPOSAL, this.proposalId)
          .then(() => {
            this.$store.commit(AppMutation.ADD_NOTIFICATION, {
              type: NotificationType.SUCCESS,
              title: 'general.notifications.update',
              content: 'proposal.create.notifications.update_success'
            })
          })
          .finally(() => this.isSaving = false);
    }
  },
  mounted() {
    this.customerId = 'string' === typeof this.$route.params.clientId
        ? this.$route.params.clientId
        : this.$route.params.clientId[0];

    customersService
        .getOne(this.customerId)
        .then(({data}) => this.customer = data);

    this.proposalId = 'string' === typeof this.$route.params.proposalId
        ? this.$route.params.proposalId
        : this.$route.params.proposalId[0];

    this.$store.dispatch(ProposalAction.LOAD_SALE_PRICES_ROWS_FOR_PREVIEW, {
      proposalId: this.proposalId,
      clientId: this.customerId,
    })
  },
  unmounted() {
    this.$store.commit(ProposalMutation.CLEAR_PROPOSAL_SUPPLIERS_CHOICES);
    this.$store.commit(ProposalMutation.CLEAR_PROPOSAL);
    this.$store.commit(ProposalMutation.CLEAR_SALE_PRICES);
  },
  computed: {
    ...mapGetters(['proposal', 'isProposalsProcessing']),
    draftStatus(): ProposalStatus {
      return ProposalStatus.DRAFT;
    },
    awaitingStatus(): ProposalStatus {
      return ProposalStatus.AWAITING;
    },
    acceptedStatus(): ProposalStatus {
      return ProposalStatus.ACCEPTED;
    },
    rejectedStatus(): ProposalStatus {
      return ProposalStatus.REJECTED;
    },
    archivedStatus(): ProposalStatus {
      return ProposalStatus.ARCHIVED;
    },
    proposalStatus(): string {
      return this.proposal.status.toLowerCase()
    }
  }
})
