
import { defineComponent, PropType } from 'vue';
import { mapGetters } from 'vuex';
import VueMultiselect from 'vue-multiselect';
import _ from 'lodash';
import OutlineButton from '@/components/ui/OutlineButton.vue';
import { ProposalMutation } from '@/store/proposal/proposal.mutation';
import { SupplierFields, SupplierParams, SuppliersSection, SuppliersSelectOption } from '@/model/suppliers-choices';
import { ProposalAction } from '@/store/proposal/proposal.action';

export default defineComponent({
  name: 'ProposalSuppliersFormSection',
  components: {
    OutlineButton,
    VueMultiselect,
  },
  props: {
    sectionData: {
      type: Object as PropType<SuppliersSection>,
      required: true,
    },
    index: {
      type: Number,
      required: true,
    },
  },
  data(){
    return {
      supplierParams: {
        [SupplierFields.suppliers]: [],
        [SupplierFields.countries]: [],
        [SupplierFields.products]: [],
        [SupplierFields.contracts]: [],
        [SupplierFields.services]: [],
      } as Record<string,SuppliersSelectOption[]>,
    };
  },
  mounted() {
    this.mapSectionDataToParams(this.sectionData)
  },
  watch: {
    sectionData: {
      handler(newValue: SuppliersSection) {
        this.mapSectionDataToParams(newValue)
      },
      deep: true,
    },
  },
  computed: {
    ...mapGetters(['suppliersChoices']),
    suppliersOptions() {
      const alreadySelectedValues = _.compact(_.map(this.suppliersChoices.suppliers, `params.${SupplierFields.suppliers}.0`));
      return _.reduce(
          this.suppliersChoices.availableSuppliers,
          (options, option) => {
            if (!_.includes(alreadySelectedValues, option.id)) {
              options.push(option);
            }
            return options;
          },
          [] as SuppliersSelectOption[]
      );
    },
    countriesOptions() {
      return this.sectionData.availableOptions[SupplierFields.countries] ?? []
    },
    productsOptions() {
      return this.sectionData.availableOptions[SupplierFields.products] ?? []
    },
    contractsOptions() {
      return this.sectionData.availableOptions[SupplierFields.contracts] ?? []
    },
    servicesOptions() {
      return this.sectionData.availableOptions[SupplierFields.services] ?? []
    },
    SupplierFields() {
      return SupplierFields
    },
  },
  methods: {
    mapSectionDataToParams(sectionData: SuppliersSection) {
      if (!_.isEqual(sectionData.params, this.supplierParams)) {
        this.supplierParams = {
          ...this.supplierParams,
          ..._.reduce(
              sectionData.params,
              (newParams, paramValues, paramName) => {
                newParams = {
                  ...newParams,
                  [paramName]: _.map(
                      paramValues,
                      (value: string) => {
                        return _.find(
                            paramName === SupplierFields.suppliers ?
                                this.suppliersChoices.availableSuppliers :
                                sectionData.availableOptions[paramName as SupplierFields],
                            { id: value }
                        );
                      }
                  )
                }
                return newParams;
              },
              {}
          )
        };
      }
    },
    removeSection() {
      this.$store.commit(
          ProposalMutation.REMOVE_PROPOSAL_SUPPLIERS_SECTION,
          this.index
      );
    },
    async saveSection() {
      return this.$store.commit(
          ProposalMutation.SAVE_PROPOSAL_SUPPLIERS_SECTION,
          {
            index: this.index,
            values: _.reduce(
                this.supplierParams,
                (params: SupplierParams, value, key) => {
                  params = {
                    ...params,
                    [key]: _.map(value, 'id'),
                  };
                  return params;
                },
                {} as SupplierParams
            ),
          },
      );
    },
    async supplierSelection() {
      await this.saveSection();
      await this.$store.dispatch(ProposalAction.LOAD_PROPOSAL_AVAILABLE_COUNTRIES, this.index);
      await this.$store.dispatch(ProposalAction.LOAD_PROPOSAL_AVAILABLE_PRODUCTS, this.index);
      await this.$store.dispatch(ProposalAction.LOAD_PROPOSAL_AVAILABLE_CONTRACTS, this.index);
      return this.$store.dispatch(ProposalAction.LOAD_PROPOSAL_AVAILABLE_SERVICES, this.index);
    },
    async countriesSelection() {
      await this.saveSection();
      await this.$store.dispatch(ProposalAction.LOAD_PROPOSAL_AVAILABLE_PRODUCTS, this.index);
      await this.$store.dispatch(ProposalAction.LOAD_PROPOSAL_AVAILABLE_CONTRACTS, this.index);
      return this.$store.dispatch(ProposalAction.LOAD_PROPOSAL_AVAILABLE_SERVICES, this.index);
    },
    async productsSelection() {
      await this.saveSection();
      await this.$store.dispatch(ProposalAction.LOAD_PROPOSAL_AVAILABLE_CONTRACTS, this.index);
      return this.$store.dispatch(ProposalAction.LOAD_PROPOSAL_AVAILABLE_SERVICES, this.index);
    },
    async contractsSelection() {
      await this.saveSection();
      return this.$store.dispatch(ProposalAction.LOAD_PROPOSAL_AVAILABLE_SERVICES, this.index);
    },
    async servicesSelection() {
      await this.saveSection();
    },
  },
})
