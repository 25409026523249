
import { defineComponent } from 'vue';
import PercentInput from '@/components/ui/PercentInput.vue';
import { mapGetters } from 'vuex';
import { useField, useForm } from 'vee-validate';
import * as yup from 'yup';

export default defineComponent({
  name: "SalePriceActions",
  components: {PercentInput},
  emits: ['update:selected', 'update:grossMargin'],
  setup() {
    useForm({
      validationSchema: yup.object({
        rowGrossMargin: yup.number().required().moreThan(0).lessThan(101)
      })
    });

    const {value: grossMarginField, meta: grossMarginMeta} = useField('rowGrossMargin', {}, {
      initialValue: 0,
    });

    return {
      grossMarginField,
      grossMarginMeta
    }
  },
  props: {
    isSurcharges: {
      required: false,
      type: Boolean,
      default: false,
    },
    selected: {
      type: Boolean
    },
    grossMargin: {
      type: [String, Number]
    }
  },
  computed: {
    ...mapGetters(['showGrossMargin', 'proposal', 'isProposalEditable']),
    isSelected: {
      get(): undefined | boolean {
        return this.selected;
      },
      set(value: boolean): void {
        this.$emit('update:selected', value);
      }
    },
    rowGrossMargin: {
      get(): undefined | number | string {
        return this.grossMargin;
      },
      set(value: number | string): void {
        this.$emit('update:grossMargin', value);
        this.grossMarginField = +value;
      }
    }
  }
})
