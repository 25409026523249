import { vModelText as _vModelText, createElementVNode as _createElementVNode, withDirectives as _withDirectives, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "flex flex-row flex-1 ml-2 p-5 bg-white" }
const _hoisted_2 = ["disabled"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _withDirectives(_createElementVNode("textarea", {
      class: "w-full h-full p-2 resize-none rounded-md border-2 border-gray-200",
      disabled: !_ctx.isProposalEditable,
      "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.description) = $event))
    }, null, 8, _hoisted_2), [
      [_vModelText, _ctx.description]
    ])
  ]))
}