
import { defineComponent } from 'vue';
import ContentSection from '@/components/proposal/form/content/ContentSection.vue';
import { mapGetters } from 'vuex';
import { ProposalMutation } from '@/store/proposal/proposal.mutation';
import ParagraphSection from '@/components/proposal/form/content/ParagraphSection.vue';
import SectionButton from '@/components/ui/SectionButton.vue';
import { Paragraph } from '@/store/proposal/content/paragraph';
import EditParagraphModal from '@/components/proposal/form/content/EditParagraphModal.vue';
import Modal from '@/components/ui/Modal.vue';
import ContentLoader from '@/components/ui/ContentLoader.vue';

export default defineComponent({
  name: "ProposalContent",
  components: {
    ContentLoader,
    Modal,
    EditParagraphModal,
    SectionButton,
    ParagraphSection,
    ContentSection,
  },
  data(): { newParagraph: Paragraph, showAddModal: boolean } {
    return {
      newParagraph: {
        title: '',
        content: ''
      },
      showAddModal: false
    }
  },
  methods: {
    handleConfirmClick(): void {
      this.$store.commit(ProposalMutation.ADD_CONTENT_PARAGRAPH, {...this.newParagraph});
      this.newParagraph = {title: '', content: ''};
    },
    handleRemoveSectionClick(paragraph: Paragraph): void {
      this.$store.commit(ProposalMutation.REMOVE_PARAGRAPH, paragraph);
    }
  },
  computed: {
    ...mapGetters([
      'isProposalLoading',
      'isProposalEditable',
      'introText',
      'generalDisclaimer',
      'terms',
      'gdpr',
      'paragraphs',
      'outboundDisclaimers',
      'returnsDisclaimers',
    ]),
    introTextMutation(): ProposalMutation {
      return ProposalMutation.SET_CONTENT_INTRO_TEXT;
    },
    generalDisclaimerMutation(): ProposalMutation {
      return ProposalMutation.SET_CONTENT_GENERAL_DISCLAIMER;
    },
    termsMutation(): ProposalMutation {
      return ProposalMutation.SET_CONTENT_TERMS;
    },
    gdprMutation(): ProposalMutation {
      return ProposalMutation.SET_CONTENT_GDPR;
    },
    newParagraphMutation(): ProposalMutation {
      return ProposalMutation.CHANGE_PARAGRAPH
    },
    outboundDisclaimersMutation(): ProposalMutation {
      return ProposalMutation.SET_CONTENT_OUTBOUND_DISCLAIMERS;
    },
    returnsDisclaimersMutation(): ProposalMutation {
      return ProposalMutation.SET_CONTENT_RETURNS_DISCLAIMERS;
    }
  }
})
