<template>
  <div class="layout-title flex items-center">
    <slot/>
  </div>
</template>

<script>
import { defineComponent } from 'vue';

export default defineComponent({
  name: "LayoutHeader"
})
</script>

<style scoped>
.layout-title {
  padding: 26px 0;
}
</style>