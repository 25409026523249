
import { defineComponent } from 'vue';
import ProposalHeader from '@/components/proposal/ProposalHeader.vue';
import LayoutHeader from '@/components/layout/LayoutHeader.vue';
import OutlineButton from '@/components/ui/OutlineButton.vue';
import Modal from '@/components/ui/Modal.vue';

interface Recipient {
  email: string;
}

interface ProposalSendState {
  recipients: Recipient[];
  showAddRecipientModal: boolean;
  email: string;
  editRecipient: null | Recipient;
  message: string;
}

export default defineComponent({
  name: "ProposalSend",
  components: {Modal, OutlineButton, LayoutHeader, ProposalHeader},
  data(): ProposalSendState {
    return {
      recipients: [],
      showAddRecipientModal: false,
      email: '',
      editRecipient: null,
      message: ''
    }
  },
  methods: {
    handleConfirmClick(): void {
      if (!this.editRecipient) {
        this.recipients = [...this.recipients, {email: this.email}];
      }

      if (this.editRecipient) {
        this.editRecipient.email = this.email;
        this.editRecipient = null;
      }

      this.email = '';
    },
    edit(recipient: Recipient): void {
      this.editRecipient = recipient;
      this.email = recipient.email;
      this.showAddRecipientModal = true;
    },
    remove(recipient: Recipient): void {
      this.recipients = this.recipients.filter((item: Recipient) => item !== recipient);
    }
  }
})
