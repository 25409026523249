import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, normalizeClass as _normalizeClass, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-07acaa33"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "flex flex-row" }
const _hoisted_2 = { class: "flex flex-col" }
const _hoisted_3 = { class: "text-light text-gray-600" }
const _hoisted_4 = { class: "pb-2" }
const _hoisted_5 = { class: "flex flex-row" }
const _hoisted_6 = { class: "flex flex-col" }
const _hoisted_7 = { class: "text-light text-gray-600" }
const _hoisted_8 = { class: "pb-2" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", null, [
    _createElementVNode("div", {
      class: _normalizeClass(["flex flex-col w-full p-3 bg-white", {'sale-prices-table__section--selected': _ctx.isSelected}])
    }, [
      _createElementVNode("div", _hoisted_1, [
        _createElementVNode("div", _hoisted_2, [
          _createElementVNode("div", _hoisted_3, _toDisplayString(_ctx.$t('proposal.general.supplier')), 1),
          _createElementVNode("div", _hoisted_4, _toDisplayString(_ctx.salePriceRow.supplier || "-"), 1)
        ])
      ]),
      _createElementVNode("div", _hoisted_5, [
        _createElementVNode("div", _hoisted_6, [
          _createElementVNode("div", _hoisted_7, _toDisplayString(_ctx.$t('proposal.general.surcharge')), 1),
          _createElementVNode("div", _hoisted_8, _toDisplayString(_ctx.salePriceRow.product || "-"), 1)
        ])
      ])
    ], 2)
  ]))
}