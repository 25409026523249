
import { defineComponent } from 'vue';

export default defineComponent({
  name: "TabsNavigation",
  props: {
    tabs: {
      type: Array,
      required: true
    }
  }
})
