
import { defineComponent } from 'vue';
import { mapGetters } from 'vuex';

export default defineComponent({
  name: "SalePriceProductDescriptions",
  props: {
    modelValue: {
      required: true,
      type: String,
    },
  },
  computed: {
    ...mapGetters(['isProposalEditable']),
    description: {
      get() {
        return this.modelValue;
      },
      set(value: string) {
        this.$emit('update:modelValue', value);
      }
    },
  }
})
