
import { defineComponent } from 'vue';
import { ProposalStatus } from '@/model/proposal-status';

export default defineComponent({
  name: "ProposalStatus",
  props: {
    type: {
      type: String,
      required: true
    }
  },
  computed: {
    status(): string {
      return this.type.toLowerCase();
    },
    statusClass(): string {
      switch (this.status) {
        case ProposalStatus.DRAFT:
          return 'proposal-status--draft';
        case ProposalStatus.ARCHIVED:
          return 'proposal-status--archived';
        case ProposalStatus.ACCEPTED:
          return 'proposal-status--accepted';
        case ProposalStatus.AWAITING:
          return 'proposal-status--awaiting';
        case ProposalStatus.REJECTED:
          return 'proposal-status--rejected';
        default:
          return '';
      }
    }
  }
})
